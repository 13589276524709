<template>
  <div class="solution-detail-container">
    <!-- 顶部标题+logo -->
    <header>
      <div class="img-wrapper">
        <img src="@/assets/images/solution/color_logo.png" />
        <template v-if="caseDetail.logo">
          <div class="divide-line" />
          <img @click="openCustomOfficialSite" :src="caseDetail.logo" />
        </template>
      </div>
      <h2 class="wrapper-title">{{ caseDetail.title }}</h2>
    </header>
    <!-- cms内容区域 -->
    <div class="md-wrapper">
      <van-skeleton title :row="5" :loading="loading">
        <article class="markdown-body" v-html="markHtml"></article>
        <van-empty v-if="!markHtml" description="暂无数据" />
      </van-skeleton>
    </div>
    <!-- 视频区域 -->
    <div v-if="videoList && videoList.length > 0" class="video-wrapper">
      <h2 class="wrapper-title">
        Demo视频
      </h2>
      <video-space class="v-space" :showDefaultPoster="false" :videoList="videoList"></video-space>
    </div>
  </div>
</template>
<script>
import { cmsApi } from '../../utils/cms'
import showdown from 'showdown'
import _ from 'lodash-es'
import VideoSpace from '@/components/videoSpace.vue'

export default {
  components: {
    VideoSpace
  },
  created () {
    this.fetchDetail()
  },
  data () {
    return {
      caseDetail: {
        content: '',
        title: '',
        logo: ''
      },
      markHtml: '',
      loading: true,
      videoList: []
    }
  },
  watch: {
    '$route' () {
      this.fetchDetail()
    }
  },
  methods: {
    fetchDetail () {
      cmsApi.getSolutionDetail(this.$route.query.id).then(resp => {
        const detail = _.get(resp, 'data.industrySolutionsV2Item[0]', {})
        this.caseDetail.logo = _.get(detail, 'logo.urls[0]', '')
        this.caseDetail.title = _.get(detail, 'solutionTitle', '')
        this.caseDetail.content = this.convertMD(_.get(detail, 'content.html', ''))
        this.caseDetail.logoLink = _.get(detail, 'logoLink.url', '')
        this.videoList = _.get(detail, 'demoVideos.urls', []).map(v => ({ video: v }))
        this.loading = false
      })
    },
    openCustomOfficialSite () {
      if (!this.caseDetail.logoLink) {
        return
      }
      window.open(this.caseDetail.logoLink)
    },
    convertMD (data) {
      const converter = new showdown.Converter()
      converter.setOption('tables', true)
      const text = data.toString()
      this.markHtml = converter.makeHtml(text)
    }
  }
}
</script>

<style lang="less">
.md-wrapper {
  margin: 32px 30px 32px;

  .markdown-body {
    // width: 100%;
    font-size: 24px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 24px;
      margin-bottom: 20px;
      font-weight: 600;
      line-height: 1.25;
    }

    h1,
    h2 {
      padding-bottom: 0.5em;
      border-bottom: 1px solid #eaecef;
      font-size: 1.3em;
    }

    h1 {
      font-size: 1.6em;
    }

    ul,
    ol {
      padding-left: 2em;
      list-style-type: disc;
    }

    ol {
      li {
        list-style-type: decimal;
      }
    }

    ul {
      li {
        list-style-type: disc;
      }
    }

    ul ul {
      list-style-type: circle;
    }

    ul ul ul {
      list-style-type: square;
    }

    ul li {
      line-height: 1.5;
    }

    blockquote,
    details,
    dl,
    ol,
    p,
    pre,
    table,
    ul {
      margin-top: 0;
      margin-bottom: 16px;
    }

    em {
      font-style: normal;
    }

    p {
      line-height: 1.5;
    }

    img {
      max-width: 100%;
      margin: 20px 0;
    }

    pre {
      padding: 20px;
      overflow: auto;
      line-height: 1.45;
      background-color: #f6f8fa;
      border-radius: 3px;
    }

    table {
      display: block;
      width: 690px;
      overflow: auto;

      thead tr {
        background-color: #f6f8fa;
      }

      tr {
        &:nth-child(even) {
          background-color: #f6f8fa;
        }
      }

      td,
      th {
        padding: 15px 15px;
      }

      td {
        line-height: 32px;
      }
    }
  }
}
</style>

<style scoped lang="less">
.solution-detail-container {

  .wrapper-title {
    margin-top: 40px;
    padding-bottom: 20px;
    font-size: 36px;
    line-height: 52px;
    font-weight: bolder;
    color: #333333;
    border-bottom: 1px solid rgba(0, 0, 0, .06);
  }

  header {
    padding: 32px 30px 0;

    .img-wrapper {
      display: flex;

      .divide-line {
        height: 40px;
        width: 4px;
        margin: 0 20px;
        background-color: @blue;
      }

      img {
        height: 40px;
      }
    }
  }

  .video-wrapper {
    margin: 32px 30px 32px;

    .v-space {
      margin-top: 32px;
    }
  }
}
</style>
