<template>
  <div style="width: 100%">
    <a-row :gutter="[12, 12]">
      <a-col v-for="(item, index) in videoList" :key="index" :span="12">
        <video-card :showDefaultPoster="showDefaultPoster" :card-info="item" @toVideoPage="toVideoPage"></video-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import VideoCard from './videoCard.vue'
export default {
  components: {
    VideoCard
  },
  props: {
    videoList: {
      type: Array,
      default: () => {}
    },
    showDefaultPoster: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    toVideoPage (val) {
      window.open(val.video)
    }
  }
}
</script>

<style lang="less">
.video-space-modal-wrapper {
  font-family: PingFangSC-Regular, PingFang SC;
  .ant-modal-header {
    border-bottom: none !important;
  }
  .ant-modal-body {
    padding: 0 24px;
  }
  .video-player {
    margin-top: 12px;
    padding-bottom: 16px;
  }
  .video-desc {
    color: rgba(0, 0, 0, 0.85);
    font-family: PingFangSC-Regular, PingFang SC;
  }
}
</style>
