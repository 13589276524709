<template>
  <div class="wrapper" @click="handleClick">
    <div class="video-card">
      <div class="aspect-ratio">
        <div class="video-poster">
          <template v-if="posterUrl">
            <img class="poster" :src="posterUrl" />
          </template>
          <template v-else>
            <video autoplay playsinline webkit-playsinline muted :src="cardInfo.video"></video>
          </template>
          <img class="logo" :src="playLogo" />
        </div>
      </div>
      <div v-if="cardInfo.title" class="video-info">
        <div class="title">{{ cardInfo.title }}</div>
        <div class="desc">{{ cardInfo.description }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultPoster from '@/assets/images/product/img_logo_web.png'
import playLogo from '@/assets/images/product/icon_play.png'
export default {
  components: {
  },
  props: {
    // { title: '', description: '', videoLink: '', poster: '' }
    cardInfo: {
      type: Object
    },
    showDefaultPoster: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      playLogo
    }
  },
  computed: {
    posterUrl () {
      return this.cardInfo.poster || (this.showDefaultPoster && defaultPoster)
    }
  },
  methods: {
    handleClick (e) {
      this.$emit('toVideoPage', this.cardInfo)
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  position: relative;
}

.aspect-ratio {
  position: relative;
  width: 100%;
  padding-bottom: (156 / 278) * 100%;
}

.video-card {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  font-family: PingFangSC-Regular, PingFang SC;
  border-radius: 8px;
  border: 1px solid #EDEDED;
  top: 0px;
  background: #FFFFFF;
  .poster {
    // position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .logo {
    position: absolute;
    height: 64px;
    width: 64px;
  }
  .video-poster {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(245, 246, 250, 1);
  }
  .video-info {
    padding: 16px 12px;
    width: 100%;
    .title {
      font-weight: 500;
      font-family: PingFangSC-Medium, PingFang SC;
      line-height: 44px;
      font-size: 28px;
      height: 44px;
      color: rgba(0, 0, 0, 0.85);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: break-word;
      width: 100%;
      display: inline-block;
    }
    .desc {
      font-weight: 400;
      margin-top: 4px;
      font-family: PingFangSC-Regular, PingFang SC;
      line-height: 40px;
      height: 80px;
      font-size: 24px;
      color: rgba(0, 0, 0, 0.45);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
    }
  }
}

</style>>
